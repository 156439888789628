<template>
  <div class="app-container">
    <div style="margin-bottom:10px;position: relative;">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="测试数据" name="1">
          <el-form
            size="small"
            label-width="120px"
            class="form_style"
            :inline="true"
          >
            <el-form-item label="开票项目">
              <el-input
                style="width:140px"
                v-model="testForm.shotName"
                placeholder="请输入开票项目"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="开票明细">
              <el-input
                style="width:140px"
                v-model="testForm.itemName"
                placeholder="请输入开票明细"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-button
                type="primary"
                @click="testData()"
                plain
                :loading="btnLoading"
                icon="Document"
                >测试</el-button
              >
            </el-form-item>
            <el-form-item label="测试结果：" v-if="showStatus">
              <span v-if="resultCode"
                >科目编码：<span style="color:#17a2b8">{{
                  resultCode
                }}</span></span
              >
              &nbsp;&nbsp;<span v-if="resultSummary"
                >费用类型：<span style="color:#17a2b8">{{
                  resultSummary
                }}</span></span
              >
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <div style="position: absolute;top:3px;right:35px">
        <el-button type="primary" @click="add()" size="small" icon="Plus"
          >新增</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      style="width: 100%;"
      border
      :height="contentStyleObj"
      v-loading="loading"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      default-expand-all
      :row-class-name="tableRowClassName"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column
        prop="shotName"
        label="开票项目"
        min-width="470"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div style="display:inline-flex">
            <el-tooltip :disabled="!scope.row.shotName" placement="top-start">
              <template #content>
                <div style="max-width: 450px;">
                  {{ filterText(scope.row.shotName) }}
                </div>
              </template>
              <div>
                <p
                  v-for="(item, index) in scope.row.shotNameArr"
                  :key="index"
                  style="max-width:460px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                >
                  <el-tag
                    v-for="tag in item"
                    :key="tag"
                    :type="tag.match('!') ? 'danger' : 'primary'"
                    style="margin-right: 5px;margin-bottom: 5px;"
                  >
                    <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
                    <span v-else>{{ tag }}</span>
                  </el-tag>
                </p>
              </div>
            </el-tooltip>

            <div style="position: absolute;right: 10px;top:5px">
              <el-button
                size="small"
                type="text"
                @click="editShotName(scope.row)"
                :disabled="!scope.row.id"
                >编辑</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="itemName"
        label="开票明细"
        min-width="760"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div style="display:inline-flex">
            <el-tooltip :disabled="!scope.row.itemName" placement="top-start">
              <template #content>
                <div style="max-width: 500px;">
                  {{ filterText(scope.row.itemName) }}
                </div>
              </template>
              <div>
                <p
                  v-for="(item, index) in scope.row.itemNameArr"
                  :key="index"
                  style="max-width:706px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                >
                  <el-tag
                    v-for="tag in item"
                    :key="tag"
                    :type="tag.match('!') ? 'danger' : 'primary'"
                    style="margin-right: 5px;margin-bottom: 5px;"
                  >
                    <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
                    <span v-else>{{ tag }}</span>
                  </el-tag>
                </p>
              </div>
            </el-tooltip>

            <div style="position: absolute;right: 10px;top:5px">
              <el-button
                size="small"
                type="text"
                @click="editName(scope.row)"
                :disabled="!scope.row.id"
                >编辑</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="科目编码" width="120" prop="amount">
        <template #default="scope">
          <el-input size="small" v-model="scope.row.resultCode"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="费用类型" width="200" prop="amount">
        <template #default="scope">
          <el-input size="small" v-model="scope.row.resultSummary"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="90" align="center">
        <template #default="scope">
          <span style="cursor: pointer;color: #17a2b8;">
            <i class="iconfont icon-jiahao" @click="addChildren(scope.row)"></i>
            <i
              class="iconfont icon-shanchu"
              style="color:red;margin-left: 12px;"
              @click="del(scope.row, scope.$index, list)"
            ></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px;text-align: right;">
      <el-button size="small" type="success" @click="saveAll"
        ><i class="iconfont icon-baocun"></i> 保存</el-button
      >
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="编辑开票明细"
    width="835px"
  >
    <div>
      <el-button size="small" @click="addItemNameArr" type="primary" plain>
        新增
      </el-button>
      <p
        v-for="(item, index) in form.itemNameArr"
        :key="index"
        style="margin-top: 10px;"
      >
        <el-tag
          v-for="tag in item"
          :key="tag"
          :type="tag.match('!') ? 'danger' : 'primary'"
          style="margin-right: 5px;margin-bottom: 5px;"
          closable
          @close="handleCloseItemName(tag, item)"
        >
          <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
          <span v-else>{{ tag }}</span>
        </el-tag>
        <el-input
          v-if="item.inputVisible"
          ref="InputRef"
          v-model="item.inputValue"
          size="small"
          @keyup.enter="handleInputItemName(item, item.inputValue)"
          @blur="handleInputItemName(item, item.inputValue)"
          style="width:120px"
        />
        <el-button v-else size="small" @click="showInputItemName(item)">
          +添加
        </el-button>
      </p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveItemName"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisibleShot"
    title="编辑开票项目"
    width="835px"
  >
    <div>
      <el-button size="small" @click="addShotNameArr" type="primary" plain>
        新增
      </el-button>
      <p
        v-for="(item, index) in form.shotNameArr"
        :key="index"
        style="margin-top: 10px;"
      >
        <el-tag
          v-for="tag in item"
          :key="tag"
          :type="tag.match('!') ? 'danger' : 'primary'"
          style="margin-right: 5px;margin-bottom: 5px;"
          closable
          @close="handleCloseShotName(tag, item)"
        >
          <span v-if="tag.match('!')">{{ tag.substring(1) }}</span>
          <span v-else>{{ tag }}</span>
        </el-tag>
        <el-input
          v-if="item.inputVisible"
          ref="InputRef"
          v-model="item.inputValue"
          size="small"
          @keyup.enter="handleInputShotName(item, item.inputValue)"
          @blur="handleInputShotName(item, item.inputValue)"
          style="width:120px"
        />
        <el-button v-else size="small" @click="showInputShotName(item)">
          +添加
        </el-button>
      </p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisibleShot = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveShotName"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <invoiceSample ref="invoiceSample" @submit="addLen" />
</template>

<script>
import {
  invoiceListGz,
  autoInvoiceSave,
  autoInvoiceTest,
} from "@/api/automatic";
import invoiceSample from "./components/invoiceSample.vue";
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
export default {
  name: "",
  components: {
    invoiceSample,
  },
  props: {},
  data() {
    return {
      list: [],
      contentStyleObj: {},
      form: {},
      dialogVisible: false,
      dialogVisibleShot:false,
      testForm: {
        shotName:"",
        itemName: "",
      },
      lastId: 0,
      resultSummary: "",
      showStatus: false,
      btnLoading: false,
      resultCode: "",
    };
  },
  mounted() {
    this.getList();
    this.contentStyleObj = this.$getHeight(235);
  },
  methods: {
    getList() {
      invoiceListGz({}).then((res) => {
        if (res.data.msg == "success") {
          this.lastId = res.data.data.lastId + 1;
          this.list = res.data.data.list ? res.data.data.list : [];
          this.changeStr(this.list);
        }
      });
    },
    // 处理字符串分割
    handleStringSplit(str, prop) {
      // 分割字符串并处理
      if (str.indexOf("&&") != -1) {
        let arr = str.split("&&");
        let newArr = [];
        arr.map((item) => {
          if (item.indexOf("||") != -1) {
            let a = item.split("||");
            let c = [];
            a.map((v) => {
              c.push(v);
            });
            newArr.push(c);
          } else {
            newArr.push([item]);
          }
        });
        return newArr;
      } else {
        let arr = str.split("||");
        let newArr = [];
        if (arr[0]) {
          newArr = [arr];
        }
        return newArr;
      }
    },
    changeStr(list) {
      for (let i = 0; i < list.length; i++) {
      list[i].itemNameArr = this.handleStringSplit(list[i].itemName, 'itemName');
      list[i].shotNameArr = this.handleStringSplit(list[i].shotName, 'shotName');
      if (list[i].children && list[i].children.length > 0) {
        this.changeStr(list[i].children);
      }
    }
    },
    //修改对方户名--start
    editName(row) {
      this.form = deepClone(row);
      this.dialogVisible = true;
    },
    showInputItemName(row) {
      row.inputVisible = true;
    },
    handleInputItemName(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    handleCloseItemName(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    addItemNameArr() {
      this.form.itemNameArr.push([]);
    },
    saveItemName() {
      this.changeItemName(this.list);
      this.dialogVisible = false;
    },
    changeItemName(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].itemNameArr = this.form.itemNameArr;
          let or = "";
          let not = "";
          let and = "";
          this.form.itemNameArr.map((v) => {
            or += v.join("||") + "&&";
          });
          list[i].itemName = or.substring(0, or.length - 2);
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeItemName(list[i].children);
        }
      }
    },

    editShotName(row) {
      this.form = deepClone(row);
      this.dialogVisibleShot = true;
    },
    // 开票项目的
    showInputShotName(row) {
      row.inputVisible = true;
    },
    handleInputShotName(row, value) {
      if (value) {
        this.$confirm("是否包含" + value + "？", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            row.push(value);
          })
          .catch(() => {
            row.push("!" + value);
          });
      }
      row.inputVisible = false;
      row.inputValue = "";
    },
    handleCloseShotName(tag, row) {
      row.splice(row.indexOf(tag), 1);
    },
    addShotNameArr() {
      this.form.shotNameArr.push([]);
    },
    saveShotName() {
      this.form.shotNameArr = this.form.shotNameArr.filter(v=>v[0])
      this.changeShotName(this.list);
      this.dialogVisibleShot = false;
    },
    changeShotName(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == this.form.id) {
          list[i].shotNameArr = this.form.shotNameArr;
          let or = "";
          let not = "";
          let and = "";
          this.form.shotNameArr.map((v) => {
            or += v.join("||") + "&&";
          });
          list[i].shotName = or.substring(0, or.length - 2);
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.changeShotName(list[i].children);
        }
      }
    },

    //修改对方户名--end
    saveAll() {
      autoInvoiceSave({ list: this.list }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.getList();
        }
      });
    },
    //新增一级
    add() {
      this.$refs.invoiceSample.init(0, this.lastId);
    },
    //新增一级
    addLen(form) {
      if (form.parentId) {
        this.checkId(this.list, form);
      } else {
        this.list.push(form);
        this.lastId = this.lastId + 1;
      }
    },
    checkId(list, form) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == form.parentId) {
          if (list[i].children) {
            list[i].children.push(form);
          } else {
            list[i].children = [];
            list[i].children.push(form);
          }
          this.lastId = this.lastId + 1;
          break;
        }
        if (list[i].children && list[i].children.length > 0) {
          this.checkId(list[i].children, form);
        }
      }
    },
    //新增下级
    addChildren(row) {
      this.$refs.invoiceSample.init(row.id, this.lastId);
    },
    //删除
    del(row, index, data) {
      if (row.parent_id == 0) {
        let firstIndex = data.findIndex((item) => item.id === row.id);
        data.splice(firstIndex, 1);
      } else {
        let parentObject = this.findNodeIndexInTree(
          data,
          row.id,
          row.parent_id
        ); //父节点对象
        if (parentObject) {
          let index = parentObject.children.findIndex(
            (item) => item.id === row.id
          ); //在父节点中获取子节点Index
          parentObject.children.splice(index, 1);
          if (parentObject.children.length == 0) {
            parentObject.children = null;
          }
        }
      }
    },
    findNodeIndexInTree(tree, targetA, targetParentId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id == targetParentId) {
          return node;
        }
        if (Array.isArray(node.children)) {
          const childIndex = this.findNodeIndexInTree(
            node.children,
            targetA,
            targetParentId
          );
          if (childIndex !== null) {
            return childIndex;
          }
        }
      }
      return null; // Node not found
    },
    // 样式
    tableRowClassName({ row, rowIndex }) {
      let status = false;
      if (!row.parent_id) {
        status = true;
      }
      if (status) {
        return "first-row";
      }
      return "";
    },
    //测试数据
    testData() {
      if (!this.testForm.itemName && !this.testForm.shotName) {
        this.$qzfMessage("请输入", 1);
        return;
      }
      this.btnLoading = true;
      autoInvoiceTest(this.testForm).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.showStatus = true;
          this.resultSummary = res.data.data.summary;
          this.resultCode = res.data.data.code;
        }
      });
    },
    //渲染值
    filterText(text) {
      let str = "";
      if (text) {
        str = text.replace(/\|\|!/g, "不包括");
        str = str.replace(/\&\&!/g, "并且不包括");
        str = str.replace(/\&\&/g, "并且包括");
        str = str.replace(/\|\|/g, "包括");
        str = str.replace(/!/g, "不包括");
        if (text.indexOf("!") != 0) {
          str = "开票项目包括" + str;
        } else {
          str = "开票项目" + str;
        }
      }
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
.flex_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancel {
  color: #17a2b8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 40px;
}
:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}
:deep(.el-collapse-item__header) {
  height: 30px;
  line-height: 30px;
}
.form_style .el-form-item {
  margin-bottom: 5px;
}
</style>
<style>
.el-table .first-row {
  background: #ecf7f9;
}
</style>
