<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="手机号/公司名称"
          v-model.trim="listQuery.name"
          style="width: 160px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button size="small" type="primary" @click="getList">
          <el-icon><Search /></el-icon><span> 搜索</span>
        </el-button>
        <el-select
          size="small"
          v-model="listQuery.from"
          @change="getList"
          style="width: 120px"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="报税小程序" value="taxApp"></el-option>
          <el-option label="近十五天到期" value="expireSoon"></el-option>
        </el-select>
      </div>
      <div></div>
      <el-button type="primary" size="small" @click="open">授权管理</el-button>
    </div>
    <el-table
      stripe
      :data="list"
      :height="contentStyleObj"
      style="width: 100%"
      border
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column
        prop="mobile_rec"
        label="手机号"
        align="left"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="districtName" label="公司名称" min-width="250">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ item.name }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="税局" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ $cityFilter(item.districtCode) }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="法人" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left">{{ item.manager }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="来源" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left" v-if="item.from == 'taxApp'">
              报税小程序
            </p>
            <p style="text-align: left" v-else>平台</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="激活状态" min-width="100">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left" v-if="item.appActiveStatus == '1'">
              <i class="iconfont icon-duihao"></i>已激活
            </p>
            <p style="text-align: left" v-else>
              <i class="iconfont icon-gantanhao"></i>未激活
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="购买开始激活时间" width="150">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p style="text-align: left" v-if="item.activeStartTime">
              {{ item.activeStartTime }}
            </p>
            <p style="text-align: left" v-else>--</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="购买结束激活时间" width="150">
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.eaCompanyItemSt"
            :key="index"
          >
            <p
              style="text-align: left"
              v-if="item.activeEndTime"
              :style="{ color: isPastDate(item.activeEndTime) }"
            >
              {{ item.activeEndTime }}
            </p>
            <p style="text-align: left" v-else>--</p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
        type="taxApplet"
      />
    </div>
    <el-drawer title="授权管理" v-model="visible" destroy-on-close size="60%">
      <operation ref="businessTypee" />
    </el-drawer>
  </div>
</template>

<script>
import { xcxComInfo } from "@/api/company";
import operation from "./components/operation";
export default {
  name: "taxApplet",
  components: {
    operation,
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        from: "",
        name: "",
      },
      list: [],
      total: 0,
      contentStyleObj: {},
      loading: false,
      visible: false,
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("taxApplet")
      ? localStorage.getItem("taxApplet") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(210);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      xcxComInfo(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    open() {
      this.visible = true;
    },
    isPastDate(time) {
      let today = new Date();
      let futureDate = new Date(today.getTime() + 16 * 24 * 60 * 60 * 1000);
      let year = futureDate.getFullYear();
      let month = (futureDate.getMonth() + 1).toString().padStart(2, "0"); // padStart()方法在月份和日期不足两位数时填充0
      let day = futureDate.getDate().toString().padStart(2, "0");
      let ago = year + "-" + month + "-" + day;
      return time < ago ? "red" : "black";
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
}
.iconfont {
  font-size: 13px;
  margin-right: 4px;
}
.red-font {
  color: red;
}
</style>
